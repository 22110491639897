/* 
.notfication-wrap{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    width: 100%;
    height: auto;
    max-height: 600px !important;
    padding: 0 15px;
    box-sizing: border-box;
    overflow-y: auto;
    flex-direction: column;
    @media (max-width: 768px) {
        flex-direction: column;
    }
}

.notifi-body{
    display: flex;
    flex-wrap: wrap;
    max-height: 400px;
    overflow-y: auto;
    flex-direction: row;
} */


.notification-container {
    display: flex;
    flex-wrap: wrap;
    width: 50%;
    margin: auto;
  
  }

  .notification-list {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    width: 100%;
    max-height: 450px;
    overflow-y: auto;
    margin: 10px;
  }

  .notification-card {
    width: 100%;
    box-shadow: none !important;
    margin: 5px !important;
    max-height: 80px;
    font-family: Poppins, sans-serif;
  }
  .notifi-head{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content:space-between;
    flex-direction: row;
    margin: 0 auto;
}
.notifi-head h4{
    font-size: 1.6rem;
    font-weight: 600;
    color: #28282B;
    margin: 0 auto;
    font-family: Poppins, sans-serif;
    padding: 0 15px;
    box-sizing: border-box;
    @media (max-width: 768px) {
        font-size: 1.5rem;
    }
}

@media screen and (max-width: 768px) {
    .notification-container {
        width: 100%;
    }
    .notification-list {
        width: 100%;
    }
    .notification-card {
        width: 100%;
    }
    .notifi-head h4{
        font-size: 1.5rem;
    }
  
}