.containerError{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #fff!important;
    
}
.pageNotfound{
    font-size: 2rem;
    color: #000;
    font-weight: 700;
}
.pageNotfoundImage{
    width: 200px ;
    height: auto;
}