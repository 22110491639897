/* SignIn.css */

.container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-family: Arial, Helvetica, sans-serif;
  flex-wrap: wrap;
  align-items: center;
  min-height: 100vh;
  height: 100vh;
  padding: 1rem;
  background-color: #F4D03F;
background-image: linear-gradient(132deg, #F4D03F 0%, #16A085 100%);

}
.container h2 {
  font-size: 1.6rem;
  color: #000000;
  font-weight: 700;
}
.error-message {
  color: rgb(233, 25, 25);
  font-size: 0.9rem;
  margin-bottom: 1rem;
}
.logo {
  width: auto;
  height: auto;
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}

.form img{
  width: 100px;
  height: 100px;
  align-self: center;
}
.form {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
}
.form select {
  outline: none;
  border: none;
  border-radius: 4px;
  padding: 0.5rem;
  margin-bottom: 1rem;
  font-size: 1rem;
  font-weight: 500;
  color: #2b2a2a;
  background-color: #f5f5f5;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border: 1px solid #ababab;
  margin: 1rem;
}
.form input {
  margin-left: 1rem;
}
.form button {
  margin-bottom: 1rem;
  margin-top: 1rem;
}
.success-message {
  color: #037536;
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 1rem;
}

.toggle-link {
  text-decoration: none;
  color: #037536;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 1rem;
}
@media (max-width: 600px) {
  .container {
    padding: 0.2rem;
  }
  .form {
    padding: 1rem;
  }

  .logo {
    width: 100%;
    margin-bottom: 0.1rem;
  }
}
