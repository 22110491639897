.wrapTable {
    max-width: 1300px;
    max-height: 800px;
    overflow-y: auto;
    margin: 0 auto;
    padding: 20px;
  }
  
  .tableContainer {
    margin-top: 10px;
    overflow-x: auto; /* Enable horizontal scrolling on small screens */
  }
  
  .table {
    width: 100%;
    border: 1px solid #ddd;
    font-family: Poppins;
  }
  
  
 .td {
    padding: 10px;
    text-align: left;
  }
  
 .th {
    background-color: #0ba360;
    color: #fff !important;
  }
  
  .sub-heading{
    font-size: 20px;
    font-weight: 600;

    color: #858585;
  }
  .css-1ygcj2i-MuiTableCell-root{
    color: #f9f9f9 !important;
  }
  .tc{
    border: 1px solid #ddd;
  }
  .tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .tr:hover {
    background-color: #e9e9e9;
    cursor: pointer;
  }
  
  .actions {
    display: flex;
    align-items: center;
  }
  
  .editIcon {
    color: #1e88e5;
    
    text-align: center;
    cursor: pointer;
  }
  
  .deleteIcon {
    color: #e53935;
    cursor: pointer;
  }
  
  /* Responsive Styles */
  @media screen and (max-width: 600px) {
    .tableContainer {
      overflow-x: auto;
    }
    
    .table {
      font-size: 12px;
    }
  
    .th,
    .td {
      padding: 8px;
    }
  }
  

  

  .edit-form {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
  }
  
  .edit-form-content {
    background-color: white;

    padding: 20px;
    border-radius: 5px;
  }
  
  .edit-form-row {
    position: relative;
  }
  
  .edit-form-row:hover .editIcon {
    display: none;
  }

  .headEdit{
    display: flex;
    justify-content: space-between;
  }
  .close-icon{
    cursor: pointer;
    color: #e53935;
  }
  .close-icon:hover{
    color: #b71c1c;
    background-color: transparent!important;
  }
  .Update-btn
  {
    background-color: #1e88e5;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px!important;
    box-shadow: none;
  }
  .Update-btn:hover
  {
    background-color: #5d97ed;
  }


 
  