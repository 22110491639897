.EvalText{
  height: 200px;
  width: auto;
  margin: 20px auto;
  padding: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 0 5px #ccc;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  overflow-x: auto;
  overflow-y: auto;
  text-align: justify;
  text-justify: inter-word;

  &::-webkit-scrollbar {
    width: 10px;
  }
}
.select-input{
  margin-left: 10px;
  height: 1.8rem;
}
.filter{
  margin: 30px auto;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
}
.filter-div{
 text-align: center;
}
@media screen and (max-width: 768px ){
  .filter{
    flex-direction: column;
  }
  .filter-div{
    margin: 10px 0;
  }
}



  

